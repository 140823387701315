import React, { useEffect, useState } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../hooks/UserContext';
import TablePro from '../../../components/newTable/TablePro';
import { getUtilityPiiFailedReason, getUtilityPiiHistoryById } from '../../../hooks/apis/useAppSafeApi';

const DetailTablesData = ({ data, onCancel }) => {
    const navigate = useNavigate();
    const [whenFailed, setWhenFailed] = useState([]);
    const [detailsData, setDetailsData] = useState([]);
    const { setLoading } = useUserContext();
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        setError(null);

        getUtilityPiiHistoryById(data.id)
            .then((res) => {
                setDetailsData(res);
            })
            .catch((err) => {
                console.error('Error fetching data: ', err);
                setError('Error fetching data');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [data]);


    const columns = [
        {
            name: 'Table Name',
            field: 'tableName',
            type: 'customControl',
            fnRenderCustomControl: (item) => (
                <span className={`font-5 ${item.status.toLowerCase() === 'failed' && 'cursor-pointer'}`}>
                    {item.tableName}
                </span>
            ),
        },
        {
            name: 'Status',
            field: 'status',
        },
        {
            name: 'Error',
            field: 'failureMessage',
            type: 'customControl',
            fnRenderCustomControl: (item) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: '1.2em' }}>
                        {item.failureMessage ? item.failureMessage : '...'}
                    </span>
                </div>
            ),
        },
        {
            name: 'Failure Reason',
            type: 'customControl',
            fnRenderCustomControl: (item) => {
                return (
                    <button
                        className='btn-purple'
                        style={{ height: '30px' }}
                        disabled={!item.failureReason && !item.failureMessage}
                        onClick={() => {
                            if (item.failureReason || item.failureMessage) {
                                const newWindow = window.open();
                                newWindow.document.write(`
                                    <html>
                                        <head>
                                            <title>Failure Details</title>
                                            <style>
                                                body {
                                                    font-family: Arial, sans-serif;
                                                    padding: 20px;
                                                }
                                                .table-name {
                                                    font-weight: bold;
                                                    font-size: 1.5em;
                                                }
                                                .failure-message, .failure-reason {
                                                    margin-top: 20px;
                                                    font-size: 1.2em;
                                                }
                                            </style>
                                        </head>
                                        <body>
                                            <pre ><span class="table-name">${item.tableName}</span><span class ="failure-message">: ${item.failureMessage ? item.failureMessage : 'No failure message'}</span></pre>
                                           
                                            <pre class="failure-reason">Failure Reason: ${item.failureReason ? item.failureReason : 'No failure reason'}</pre>
                                        </body>
                                    </html>
                                `);
                                newWindow.document.close();
                            }
                        }}
                    >
                        Show
                    </button>
                );
            }
        }
        
 ,
    ];

    return (
        <>
            {whenFailed?.length > 0 && (
                <div className="panel-utility">
                    <div className="panel-screen h-100"></div>
                </div>
            )}

            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1050' }} className="modal-dialog modal-dialog-centered" role="document">
                <div>
                    <div className="modal-content" style={{ border: '1px solid var(--gray-300, #D0D5DD)' }}>
                        <div className="modal-header">
                            <h5
                                className="modal-title font-5 cursor-pointer"
                                onClick={() => navigate('/utilitySetHomePage')}
                            >
                                <BsFillArrowRightCircleFill /> {data?.name}
                            </h5>
                            <button
                                onClick={() => onCancel({ id: '', name: '' })}
                                type="button"
                                className="close btn btn-transparent border-0 font-6"
                                aria-label="Close"
                            >
                                <span aria-hidden="true" className="font-6">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body font-4">
                            {detailsData.length > 0 ? (
                                <TablePro
                                    columns={columns}
                                    data={detailsData.sort((a, b) => new Date(b.startTime) - new Date(a.startTime))}
                                    minRows={4}
                                />
                            ) : error ? (
                                error
                            ) : (
                                'Loading...'
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailTablesData;
